import {Injectable, inject} from '@angular/core';
import { DataStorageEnum } from '../models/data-storage-enum.model';
import { DataStorage } from './storage.service';
import { MenuItem, PrimeIcons } from 'primeng/api';
import { PermissionService } from '@pandora/flowers';
import { TranslateService } from '@ngx-translate/core';
import { NavigateService } from './navigate.service';
import { environment } from '../../../environments/environment';
import { PermissionEnum } from '../../shared/constans/permissions.enum';

@Injectable({
  providedIn: 'root'
})
export class UiElement {
  permissionService: PermissionService;
  private geoToolsUrl = "https://geotools.pandoradevelop.it/egp/";

  constructor(private readonly dataStorage: DataStorage, private readonly navigateService: NavigateService, private readonly translateService: TranslateService) {
    this.permissionService = inject(PermissionService);
  }

  checkLanguageAvailable(): boolean {
    return this.translateService.getLangs().includes(environment.defaultLang);
  }

  initHomeItems(NO_AUTHORIZATION = false) {
    let menuItems: MenuItem[] = [];

    if (NO_AUTHORIZATION) {
      menuItems = [];
    } else {
      menuItems = [
        {
          label: this.translateService.instant('MENU.DASHBOARD'),
          icon: 'pi pi-fw pi-home',
          queryParams: { permissions: [PermissionEnum.DASHBOARD] },
          command: () => this.navigateService.goTo('home'),
        }
      ];

      menuItems = this.permissionService.checkMenuPermissions(menuItems);
    }

    this.dataStorage.updateData(DataStorageEnum.HOME_ITEMS, menuItems);
  }

  initDocsItems() {
    let menuItems: MenuItem[] = [];

    menuItems = [
      {
        label: this.translateService.instant('MENU.REFERENCE'),
        icon: 'pi pi-fw pi-pencil',
        command: () => this.navigateService.goTo('reference'),
      }
    ];

    this.dataStorage.updateData(DataStorageEnum.DOCS_ITEMS, menuItems);
  }

  initMenuItems(NO_AUTHORIZATION = false) {
    // TODO
    let menuItems: MenuItem[] = [];

    if (NO_AUTHORIZATION) {
      menuItems = [
        {
          label: this.translateService.instant('MENU.NO_AUTHORIZATION'),
          disabled: true,
          id: 'no-items',
        },
      ];
    } else {
      menuItems = [
        {
          label: this.translateService.instant('MENU.GEOTOOLS'),
          icon: 'pi pi-fw pi-map',
          queryParams: { permissions: [PermissionEnum.MENU_GEO] },
          command: () => {
            const newWindow = window.open(this.geoToolsUrl, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
          },
        },
        {
          label: this.translateService.instant('MENU.WORKFLOW'),
          icon: 'pi pi-fw pi-sitemap',
          queryParams: { permissions: [PermissionEnum.MENU_WRK, PermissionEnum.SRCH_WRK] },
          command: () => this.navigateService.goTo('workflow'),
        }
      ];

      menuItems = this.permissionService.checkMenuPermissions(menuItems);
    }

    this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
  }

  /* initMenuItems(user: User) {
    const menuItems: MenuItem[] = [];

    if (user && 'profileSelected' in user && user.profileSelected) {
      const permissions: PermissionEnum[] = user.profileSelected.permission;

      const MENU_MODULE = permissions.filter((p) => p === PermissionEnum.MENU_MODULE);
      const MENU_PERMISSION = permissions.filter((p) => p === PermissionEnum.MENU_PERMISSION);
      const MENU_APPLICATION = permissions.filter((p) => p === PermissionEnum.MENU_APPLICATION);
      const MENU_ROLE = permissions.filter((p) => p === PermissionEnum.MENU_ROLE);
      const MENU_UO = permissions.filter((p) => p === PermissionEnum.MENU_UO);
      const MENU_USER = permissions.filter((p) => p === PermissionEnum.MENU_USER);

      if (MENU_MODULE || MENU_PERMISSION || MENU_APPLICATION || MENU_ROLE) {
          const items: MenuItem[] = [];

          if (MENU_MODULE) {
            items.push({
              label: 'Modulo',
              id: 'gestione-modulo',
              command: () => this.router.navigate(['gestione-modulo']),
            });
          }

          if (MENU_PERMISSION) {
            items.push({
              label: 'Permission',
              id: 'gestione-permission',
            });
          }

          if (MENU_APPLICATION) {
            items.push({
              label: 'Applicazione',
              id: 'gestione-applicazione',
            });
          }

          if (MENU_ROLE) {
            items.push({
              label: 'Ruolo',
              id: 'gestione-ruolo',
            });
          }

          menuItems.push({
            label: 'Mappa Applicativa',
            items,
          });
      }

      if (MENU_UO) {
        menuItems.push({
          label: 'Unità Organizzativa',
          id: 'unita-organizzativa',
        });
      }

      if (MENU_USER) {
        menuItems.push({
          label: 'Utente',
          id: 'utente',
        });
      }
    }

    this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
  } */

  getMenuItems() {
    return this.dataStorage.getData(DataStorageEnum.MENU_ITEMS);
  }

  getHomeItems() {
    return this.dataStorage.getData(DataStorageEnum.HOME_ITEMS);
  }

  getDocsItems() {
    return this.dataStorage.getData(DataStorageEnum.DOCS_ITEMS);
  }

  clearMenuItems() {
    this.translateService.get('MENU.TO_LOGIN').subscribe((translated: string) => {
      const menuItems: MenuItem[] = [
        {
          label: translated,
          disabled: true,
          id: 'no-items',
        },
      ];

      this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
      this.dataStorage.updateData(DataStorageEnum.HOME_ITEMS, []);
      this.dataStorage.updateData(DataStorageEnum.DOCS_ITEMS, []);
    })
  }

  clearMenuItemsNoProfileSelected() {
    this.translateService.get('MENU.TO_PROFILE').subscribe((translated: string) => {
      const menuItems: MenuItem[] = [
        {
          label: translated,
          disabled: true,
          id: 'no-items',
        },
      ];

      this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
      this.dataStorage.updateData(DataStorageEnum.HOME_ITEMS, []);
      this.dataStorage.updateData(DataStorageEnum.DOCS_ITEMS, []);
    })
  }

  /* clearMenuItems() {
    let subscript = new Subscription();

    const sub = this.translateService.get('MENU.TO_LOGIN').subscribe((translated: string) => {
      const menuItems: MenuItem[] = [
        {
          label: translated,
          disabled: true,
          id: 'no-items',
        },
      ];

      this.dataStorage.updateData(DataStorageEnum.MENU_ITEMS, menuItems);
      subscript.unsubscribe();
    });

    subscript.add(sub);
  } */
}
