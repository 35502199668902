import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter',
})
export class NgForFilterPipe implements PipeTransform {
  transform(items: any[], field: string, value): any[] {
    if (!items) return [];

    if ((typeof value === 'boolean' && !value) || (typeof value === 'string' && !value.length)) return items;

    return items.filter(it => it[field] === value);
  }
}
