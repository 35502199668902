import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { Injectable, Injector, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class Utils {
  previousUrl: string;
  currentUrl: string;

  sanitizer: DomSanitizer;
  router: Router;

  constructor(injector: Injector) {
    this.sanitizer = injector.get(DomSanitizer);
    this.router = injector.get(Router);

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event['url'];
      })
  }

  getPreviousUrl() {
    return this.previousUrl;
  }
  getCurrentUrl() {
    return this.currentUrl;
  }

  static removeExtraSpaces(sentence: string) {
    return sentence.replace(/\s+/g, ' ').trim();
  }

  static capitalizeAllWords(sentence: string) {
    const splitChars = [" ", "'"]; // caratteri su cui effettuare lo split
    let arr;
    sentence = sentence.toLowerCase(); // messa subito in lowercase, altrimenti a ogni ciclo avrei cancellato il capitalize del ciclo precedente

    for (const splitChar of splitChars) { // itero sui caratteri su cui fare lo split
      arr = Utils.removeExtraSpaces(sentence).split(splitChar);
      for (let i = 0; i < arr.length; i++) { // itero sulle parole splittate
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
     sentence = arr.join(splitChar);
    }

    return sentence;
  }

 getImage(b64Image: string) {
    const blob = this.b64toBlob(b64Image);
    return this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
  }

 b64toBlob(b64Data: string, sliceSize=512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays);
    return blob;
  }

}
