import { Injectable, Injector } from '@angular/core';
import { CustomBaseHttpLiteService, CustomSearchCriteria } from '@pandora/flowers';
import { Endpoints } from '../endpoints/endpoints';
import { ConfigurationEndpointsConstants } from '../constans/configuration-endpoints.constants';
import { Job } from '../models/jobs.model';

@Injectable({
  providedIn: 'root'
})
export class JobService extends CustomBaseHttpLiteService<Job> {

  constructor(injector: Injector) {
    super(injector);
  }

  override getHost(): string {
    return ConfigurationEndpointsConstants.API.url.workflow;
  }
  
  buildSearchParams(criteria: CustomSearchCriteria): any {
    const params = {};
    return params;
  }

  getBaseResourceEndpoint(): string {
    return Endpoints.JOBS;
  }

  getServiceName(): string {
    return 'JobService';
  }
}
