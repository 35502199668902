import { Inject, Injectable, inject } from '@angular/core';
import { AuthFacade, AuthService, AuthorizationModel, User } from '@pandora/flowers';
import { DataStorage } from './storage.service';
import { DataStorageEnum } from '../models/data-storage-enum.model';
import { NavigateService } from './navigate.service';
import { MenuItem } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { UiElement } from './ui-element.service';
import { KeycloakService } from 'keycloak-angular';
import { lastValueFrom, noop, tap } from 'rxjs';
import { GestioneUserInfoService } from '../../shared/services/gestione-user-info.service';
import { UserInfo } from '../../shared/models/userinfo.model';
import { UserInfoProfile } from '../../shared/models/userinfoprofile.model';
import { MockedKeycloakService } from 'src/mocked-keycloak.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: User;
  userInfo: UserInfo;
  userInfoProfile: UserInfoProfile;
  userActions: MenuItem[] = [];
  officeName = '';
  tenant = '';
  lastRefreshTokenExp: number;
  subheaderActions: MenuItem[] = [];
  keycloak: KeycloakService;
  themeMode: {style: 'lara-light-indigo',type: 'light'} | {style: 'bootstrap4-dark-blue',type: 'dark'} = {style: 'lara-light-indigo',type: 'light'};

  constructor(
    private readonly authFacade: AuthFacade,
    private readonly authService: AuthService,
    private readonly dataStorage: DataStorage,
    private readonly navigateService: NavigateService,
    private readonly translateService: TranslateService,
    private readonly uiElement: UiElement,
    readonly gestioneUserInfoService: GestioneUserInfoService,
    @Inject(DOCUMENT) private document: Document
    ) {
    /* setTimeout(() => {
      this.uiElement.clearMenuItems();
    }, 115); */

    this.authService.setEntryComponentPath(environment.defaultEntryComponentPath);

    if (environment.demoMode) {
      this.keycloak = inject(MockedKeycloakService);
    } else {
      this.keycloak = inject(KeycloakService);
    }


    const interval = setInterval(() => {
      if (this.uiElement.checkLanguageAvailable()) {
        this.uiElement.clearMenuItems();
        this.clearSubheaderActions();

        clearInterval(interval);
      }
    }, 10);

    this.initUserService();
  }

  reset() {
    this.user = null;
    this.userInfo = null;
    this.userInfoProfile = null;
    this.userActions = [];
    this.officeName = '';
    this.tenant = '';
    this.lastRefreshTokenExp = null;
    this.subheaderActions = [];
  }

  initUserService() {
    this.authFacade.getUser().subscribe(async (user: User) => {
      if (user) {
        this.setUser(user);

        const interval2 = setInterval(() => {
          if (this.keycloak.getKeycloakInstance()) {
            if (this.lastRefreshTokenExp === undefined && this.keycloak.getKeycloakInstance().refreshTokenParsed) {
              this.lastRefreshTokenExp = this.keycloak.getKeycloakInstance().refreshTokenParsed.exp;
            }

            if (this.keycloak.getKeycloakInstance().refreshTokenParsed && this.lastRefreshTokenExp !== this.keycloak.getKeycloakInstance().refreshTokenParsed.exp) {
              this.lastRefreshTokenExp = this.keycloak.getKeycloakInstance().refreshTokenParsed.exp;

              this.authService
                .checkAuthAndGetUserData()
                .pipe(
                  tap((user: User) => {
                    let userInfo: User = {
                      ...user,
                    }

                    if ('profileSelected' in this.user && this.user.profileSelected) {
                      user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
                        if (element.application === environment.applicationName) {
                          userInfo = {
                            ...userInfo,
                            profileSelected: element.profiles.find((p) => p.uuid === this.user.profileSelected.uuid)
                          }
                        }
                      });
                    } else {
                      user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
                        if (element.application === environment.applicationName && element.profiles.length === 1) {
                          userInfo = {
                            ...userInfo,
                            profileSelected: element.profiles[0]
                          }
                        }
                      });
                    }

                    this.authService.storeUser(userInfo);
                    this.user = userInfo;

                    this.setUser(this.user);

                    clearInterval(interval2);
                  }),
                )
                .subscribe(noop);
            }
          }

        }, 1000);
      }

    });
  }

  setUser(user: User) {
    if(user && !('profileSelected' in user)) {
      this.uiElement.clearMenuItemsNoProfileSelected();
    }

    if (this.keycloak.getKeycloakInstance()) {
      // set user
      this.user = user;

      this.keycloak.getKeycloakInstance().onAuthRefreshSuccess();
    }

    // logout if refresh_token expired
    if (this.user) {
      const interval = setInterval(() => {
        if (!environment.demoMode && ((this.keycloak.getKeycloakInstance().refreshTokenParsed && Math.floor(Date.now() / 1000) > this.keycloak.getKeycloakInstance().refreshTokenParsed.exp) || !this.keycloak.getKeycloakInstance().refreshTokenParsed)) {
          this.logout();

          clearInterval(interval);
        }
      }, 1000);

      // set entryComponentPath and tenantPath
      this.dataStorage.updateData(DataStorageEnum.USER, this.user);

      let tenant = '';
      if (this.tenant !== '') {
        tenant = this.tenant;
      } else {
        tenant = this.user['authorization-details']['tenant'];
      }

      this.setTenantPath(tenant);
      this.authService.setEntryComponentPath(this.tenant.concat('/home'));

      // set flowers-base-layout variables
      this.userActions = this.userActions.filter((obj: MenuItem) => obj.label !== this.translateService.instant('PROFILE.CHANGE_PROFILE')
                                                                    && obj.label !== this.translateService.instant('PROFILE.CHANGE_PASSWORD')
                                                                    && obj.label !== 'PROFILE.CHANGE_PROFILE'
                                                                    && obj.label !== 'PROFILE.CHANGE_PASSWORD');

      const authorization = this.applicationNameModel();
      if (authorization) {
        if (authorization.profiles.length > 1) {
          this.userActions.unshift({
            label: this.translateService.instant('PROFILE.CHANGE_PROFILE'),
            command: () => {
              this.navigateService.goTo('profile');
            },
            id: '10'
          });

          this.userActions.sort((a, b) => Number(a.id) - Number(b.id));
        }

        if (('profileSelected' in this.user && this.user.profileSelected && authorization.profiles.length > 1) || authorization.profiles.length === 1) {
          this.uiElement.initHomeItems();
          this.uiElement.initMenuItems();
          this.subheaderActions = [
            {
              icon: 'pi pi-palette',
              command: () => this.changeDarkLightMode(),
              tooltip:'PROFILE.CHANGE_THEME',
              tooltipPosition: 'left',
            },
            {
              // label: this.translateService.instant('PROFILE.TITLE'),
              icon: 'pi pi-user',
              items: this.getUserActions(),
              tooltip:'PROFILE.TITLE',
              tooltipPosition: 'left'
            },
          ];
        }

      } else {
        // utente senza environment.applicationName (EGP)
        this.uiElement.initHomeItems(true);
        this.uiElement.initMenuItems(true);
        this.clearSubheaderActions();
      }

      // utente senza autorizzazioni
      if (!this.user['authorization-details'].authorization.length) {
        this.uiElement.initHomeItems(true);
        this.uiElement.initMenuItems(true);
        this.clearSubheaderActions();
      }

      this.uiElement.initDocsItems();

      this.gestioneUserInfoService.getTenantInfo(tenant).subscribe(tenantInfo => {

        if(!environment.demoMode && (!tenantInfo?.external && (this.userActions.length === 0 || this.userActions.findIndex(a => a.label === this.translateService.instant('PROFILE.CHANGE_PASSWORD')) === -1))) {
          this.userActions.push({
            label: this.translateService.instant('PROFILE.CHANGE_PASSWORD'),
            command: () => this.changePassword(),
            id: '80',
            icon: 'pi pi-user-edit'
          });

          this.userActions.sort((a, b) => Number(a.id) - Number(b.id));
        }
      })
    } else {
      if (this.authService.entryComponentPath === undefined) {
        this.authService.setEntryComponentPath('home');
      }

      if (window.location.href.indexOf('home') === -1) {
        this.navigateService.goTo('home');
      }
    }
  }

  changeDarkLightMode() {
    if (this.themeMode.type === 'light') {
      this.themeMode = {
        style: 'bootstrap4-dark-blue',
        type: 'dark'
      }
    } else {
      this.themeMode = {
        style: 'lara-light-indigo',
        type: 'light'
      }
    }

    const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css') as HTMLLinkElement;
    if(themeLink) {
      themeLink.href = 'assets/layout/styles/theme/' + this.themeMode.style + '/theme.css';
    }

    return this.themeMode.type;
  }

  clearSubheaderActions() {
    this.subheaderActions = [];
  }

  applicationNameModel() {
    let response: AuthorizationModel;

    if (this.user) {
      this.user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
        if (element.application === environment.applicationName) {
          response = element;
        }
      });
    }

    return response;
  }

  async logout() {
    this.reset();
    this.uiElement.clearMenuItems();
    this.dataStorage.clearAllData();
    // TODO this.gestioneUserInfoService.clear();

    const postLogoutUrl = window.location.origin.concat('/').concat(this.authService.entryComponentPath);

    if (environment.demoMode) {
      await this.keycloak.logout();
      this.authService.clearUser();
      window.location.href = postLogoutUrl;
    } else {
      this.authService.logout(postLogoutUrl);
    }
  }

  changePassword() {
    // this.uiElement.clearMenuItems();
    // this.dataStorage.clearAllData();
    // this.gestioneUserInfoService.clear();

    const postChangeUrl = window.location.origin.concat('/').concat(this.authService.entryComponentPath)

    this.authService.changePassword(postChangeUrl);
  }

  getUser(): User {
    return this.user;
  }

  setTenantPath(tenant: string) {
    this.tenant = tenant;
  }

  getUserActions(): MenuItem[] {
    return this.userActions;
  }

  getOfficeName(): string {
    return this.officeName;
  }

  async updateOtherInfo() {
    if (this.user) {
      const sourceUserInfo$ = this.gestioneUserInfoService.getWithWrapper();
      this.userInfo = await lastValueFrom(sourceUserInfo$);

      if (this.user.profileSelected) {
        this.officeName = this.userInfo.profiles.find((p: UserInfoProfile) => {
          if (p.uuid === this.user.profileSelected.uuid) {

            const date = new Date(p.creationDate);
            const yyyy = date.getFullYear();
            const mm = date.getMonth() + 1; // Months start at 0!
            const dd = date.getDate();

            let mmString = mm.toString();
            let ddString = dd.toString();

            if (dd < 10) ddString = '0' + dd;
            if (mm < 10) mmString = '0' + mm;

            const formattedToday = ddString + '/' + mmString + '/' + yyyy;

            this.userInfoProfile = {
              ...p,
              creationDate: formattedToday
            };
          }

          return p.uuid === this.user.profileSelected.uuid;
        }).uoName;
      }

      if (this.userInfo.avatarURL) {
        this.updateAvatar();
      }

    }
  }

  buildUser = (user: User): User => {
    let userInfo: User = {
      ...user,
    }

    user['authorization-details'].authorization.forEach((element: AuthorizationModel) => {
      if (element.application === environment.applicationName && element.profiles.length === 1) {
        userInfo = {
          ...userInfo,
          profileSelected: element.profiles[0]
        }
      }
    });

    this.authService.clearUser();
    this.authService.storeUser(userInfo);
    this.dataStorage.updateData(DataStorageEnum.USER, userInfo);

    this.user = userInfo;

    this.updateOtherInfo();

    return userInfo;
  }

  async updateAvatar() {
    if (this.user) {
      const source$ = this.gestioneUserInfoService.getAvatarBE();
      const response = await lastValueFrom(source$);

      this.gestioneUserInfoService.avatar = response;
    }
  }
}
