/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, Injector } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AuthService, ErrorLabelsMaps, ErrorMessage, FlowersFormBasePageComponent, InputType, TooltipMaps } from '@pandora/flowers';
import { Observable, of } from 'rxjs';
import { FakeLogin } from '../../../shared/models/fakeLogin.model';
import { NavigateService } from '../../services/navigate.service';
import { UserService } from '../../services/user.service';
import { MockedKeycloakService } from '../../../../../src/mocked-keycloak.service';
import { user } from '../../../fake-user.constant';

@Component({
  selector: 'fake-login',
  templateUrl: './fake-login.component.html',
  styleUrls: ['./fake-login.component.scss'],
})
export class FakeLoginComponent extends FlowersFormBasePageComponent<FakeLogin> {

  inputType = InputType;

  constructor(injector: Injector, private navigateService: NavigateService, private userService: UserService, private authService: AuthService) {
    super(injector);
  }

  createForm(form?: FormGroup<any>): FormGroup<any> {
    return this.fb.group({
      username: ['mario.rossi'],
      password: ['mario.rossi']
    });
  }

  fillForm(form: FormGroup<any>, model: FakeLogin): void {}

  extractDataToSubmit(form: FormGroup<any>): FakeLogin {
    const model = this.form.getRawValue() as FakeLogin;

    return {
      username: model?.username,
      password: model?.password
    };
  }

  save(model: FakeLogin): Observable<FakeLogin> {
    return of(null);
  }

  update(model: FakeLogin): Observable<FakeLogin> {
    return of(null);
  }

  async submitComplete(model: FakeLogin) {
    const keycloak = new MockedKeycloakService();
    await keycloak.login();
    this.userService.setUser(user);
    this.navigateService.goTo('home');
  }

  submitError(error: ErrorMessage) {
    return undefined;
  }

  rememberToCreateAndUseErrorLabels(
    errorLabel: ErrorLabelsMaps
  ): ErrorLabelsMaps {
    return {};
  }

  rememberToCreateAndUseTooltips(tooltip: TooltipMaps): TooltipMaps {
    return undefined;
  }

  protected loadModel(id: any): Observable<FakeLogin> {
    return null;
  }

  protected getPathKeyForTakeId(): string {
    return 'id';
  }

  ngOnInitForChildren(): void {}

  ngOnDestroyForChildren(): void {}

  pageName(): string {
    return 'FakeLoginComponent';
  }
}

