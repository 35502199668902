import { Injectable} from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakLoginOptions } from 'keycloak-js';
import { user } from './app/fake-user.constant';

@Injectable()
export class MockedKeycloakService extends KeycloakService {
  override init() {
    return Promise.resolve(true);
  }

  override getKeycloakInstance() {
    return {
      loadUserInfo: () => {
        let callback;
        Promise.resolve().then(() => {
          callback(user);
        });
        return {
          success: (fn) => callback = fn
        };
      },
      onAuthRefreshSuccess: () => {}
    } as any;
  }

  override login(options?: KeycloakLoginOptions): Promise<void> { return null }  
  override logout(redirectUri?: string): Promise<void> { return null }
}