import { NgModule } from '@angular/core';
import { FlowersModule } from '@pandora/flowers';
import { NgForFilterPipe } from './pipes/ngFor-filter.pipe';
import { WorkflowSearchComponent } from './components/workflow-search/workflow-search.component';
import { CronParserPipe } from './pipes/cron-parser.pipe';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [
    NgForFilterPipe,
    WorkflowSearchComponent,
    CronParserPipe
  ],
    imports: [
        CommonModule,
        FlowersModule
    ],
  exports: [
    CommonModule,
    FlowersModule,

    NgForFilterPipe,
    WorkflowSearchComponent,
    CronParserPipe
  ],
})
export class SharedModule {}
