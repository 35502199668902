import { Component, ElementRef, ViewChild, Injector } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from "../service/app.layout.service";
import { AuthService, FlowersBasePageComponent, User } from '@pandora/flowers';
import { Observable, map, noop, of, tap } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { GestioneUserInfoService } from '../../shared/services/gestione-user-info.service';
import {TranslateService} from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NavigateService } from '../../core/services/navigate.service';
import { environment } from 'src/environments/environment';
import { user } from '../../fake-user.constant';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss'],
})
export class AppTopBarComponent extends FlowersBasePageComponent {

    pageName(): string {
        return 'AppTopBarComponent';
    }

    ngOnDestroyForChildren(): void {}

    items!: MenuItem[];

    @ViewChild('menubutton') menuButton!: ElementRef;

    @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

    @ViewChild('topbarmenu') menu!: ElementRef;

    user$: Observable<User>;
    user: User;
    addFlag: boolean;
    customLogout;

    constructor(injector: Injector, public layoutService: LayoutService, public authService: AuthService, public userService: UserService, public gestioneUserInfoService: GestioneUserInfoService, private translateService: TranslateService, private router: Router, private navigateService: NavigateService) {
        super(injector);
    }

    ngOnInitForChildren(): void {

      const currentActions = this.userService.getUserActions();
      const isPresent = currentActions.findIndex((obj: MenuItem) => obj.id === '99') > -1;

      if (!isPresent) {
        this.customLogout = {
          label: this.translateService.instant('APPLICATION.LOGOUT'),
          command: () => {
            this.userService.logout();
          },
          id: '99',
          icon: ''
        };
      }

      this.authService
        .checkAuthAndGetUserData()
        .pipe(
          map((user: any) => this.userService.buildUser(user)),
          tap((user: User) => {
            this.authService.storeUser(user);
            this.user$ = of(user);
            this.user = user;
          }),
        )
        .subscribe(noop);

      if (environment.demoMode) {
        setTimeout(() => {
          const currentActions = this.userService.getUserActions();
          if (currentActions.filter(a => a.id === '99').length > 0) {
            this.authService.storeUser(user);
            this.user$ = of(this.userService.getUser());
            this.user = this.userService.getUser();
            this.userService.buildUser(this.user);
          }
        }, 1000);

      }
    }

    getUserActions() {
        const currentActions = this.userService.getUserActions();

        if (!this.addFlag) { // fixme: spostare l'aggiunta di un'azione altrove

          const isPresent = currentActions.findIndex((obj: MenuItem) => obj.id === '81') > -1;

          if (!isPresent) {
            currentActions.push({
              label: this.translateService.instant('PROFILE.SHOW_DETAILS'),
              command: () => this.showProfileDetails(),
              id: '81',
              icon: 'pi pi-id-card'
            });
          }

          this.addFlag = true;
        }

        return currentActions;
    }

    showProfileDetails = () => {
        this.navigateService.goTo('profile', { relativeTo: this.activatedRoute });
    }

    login() {
      if (environment.demoMode) {
        this.navigateService.goTo('login');
      } else {
        this.authService.login();
      }
    }
}
