import {APP_INITIALIZER, NgModule} from '@angular/core';
import {initializeKeycloak} from '../../keycloak-initializer';
import {KeycloakAngularModule, KeycloakService} from 'keycloak-angular';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {UserService} from './services/user.service';
import {EgpHttpInterceptor} from './services/egp-http-interceptor.service';
import {FlowersModule } from '@pandora/flowers';
import {CommonModule} from '@angular/common';
import {ShowProfileComponent} from './components/show-profile/show-profile.component';
import { FakeLoginComponent } from './components/fake-login/fake-login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MockedKeycloakService } from '../../mocked-keycloak.service';
import { environment } from '../../environments/environment';

@NgModule({
  declarations: [
    ShowProfileComponent,
    FakeLoginComponent
  ],
  imports: [
    CommonModule,
    FlowersModule,
    HttpClientModule,
    KeycloakAngularModule,
    ReactiveFormsModule,
  ],
  providers: [
    {
      provide: environment.demoMode ? MockedKeycloakService : KeycloakService,
      useClass: environment.demoMode ? MockedKeycloakService : KeycloakService
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [environment.demoMode ? MockedKeycloakService : KeycloakService, UserService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EgpHttpInterceptor,
      multi: true,
    },
    UserService
  ]
})
export class CoreModule { }
