<!-- <flowers-base-layout
  [applicationSubtitle]="undefined"
  [showFooter]="false"
  [containerFluid]="true"
  [menuOpenDefault]="true"
  [showMenuItemsIcon]="false"
  [additionalUserInfo]="userService.getOfficeName()"
  [logo]="'assets/logo.png'"
  [subheaderActions]="userService.subheaderActions"
  [showUserDropdown]="false"
  [showDropdownUserIcon]="true"
  [cssClass]="'main-bg'"
  [avatar]="gestioneUserInfoService.getAvatarImage()"
  [getAvatar]="gestioneUserInfoService.getAvatar"
>
  <router-outlet></router-outlet>
</flowers-base-layout>
 -->

 <app-layout></app-layout>
 <ngx-spinner color="#3f51b5"></ngx-spinner>