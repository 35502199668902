<h1>
  {{pageTitle | translate}}
</h1>

  <div class="splitted-view">
    <p-card class="details-info" header="{{userLabel}}">
      <ng-template pTemplate="header">
        <!-- <img alt="Profile picture" [src]="gestioneUserInfoService.getAvatarImage()" /> -->

        <custom-avatar *ngIf="imageUrl" [size]="'small'" [imageUrl]="imageUrl" [cssClass]="'avatar-profile'" [eventFunc]="openChangeModal" [title]="'GESTIONE_UTENTE.FORM.CHANGE_AVATAR' | translate"></custom-avatar>
        <custom-avatar *ngIf="!imageUrl" [size]="'small'" [cssClass]="'avatar-profile'" [eventFunc]="openChangeModal" [title]="'GESTIONE_UTENTE.FORM.CHANGE_AVATAR' | translate"></custom-avatar>

      </ng-template>
      <p-divider></p-divider>

      <div class="grid flex flex-wrap align-items-center justify-content-center">
        <ng-container *ngIf="anagraficaInfo.creationDate">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.ACTIVATION_DATE' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.creationDate}}</div>
        </ng-container>
        <ng-container *ngIf="!gestioneUserInfoService.tenantInfo.external && anagraficaInfo.exipirationDays">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.PASSWORD_EXPIRATION' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.exipirationDays}} {{(gestioneUserInfoService.userInfo.daysUntilPasswordExpiration === 1 ? 'PROFILE.PASSWORD_EXPIRATION_DAY' : 'PROFILE.PASSWORD_EXPIRATION_DAYS') | translate}}</div>
        </ng-container>
      </div>
    </p-card>
    
    <p-card class="details-data">
      <tab-view [tabs]="[{ id: 1, label: 'PROFILE.TAB_ANAGRAFICA' | translate}]" [templates]="[anagrafica]" [activeIndex]=0></tab-view>
      <ng-template #anagrafica>
      <div class="grid flex flex-wrap align-items-center justify-content-center">
        <ng-container *ngIf="anagraficaInfo.name">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.NAME' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.name}}</div>
        </ng-container>
        <ng-container *ngIf="anagraficaInfo.surname">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.SURNAME' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.surname}}</div>
        </ng-container>
        <ng-container *ngIf="anagraficaInfo.codiceFiscale">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.TAX_CODE' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.codiceFiscale}}</div>
        </ng-container>
        <ng-container *ngIf="anagraficaInfo.email">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.EMAIL' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.email}}</div>
        </ng-container>
        <ng-container *ngIf="anagraficaInfo.phone">
          <div class="col-4 font-bold text-left p-3">{{'PROFILE.PHONE' | translate}}:</div>
          <div class="col-8 text-left p-3 align-items-center justify-content-center"> {{anagraficaInfo.phone}}</div>
        </ng-container>
      </div>
    </ng-template>
    </p-card>
  </div>

<custom-modal
  id="id_changeModal"
  #changeModal
  [showCancelBtn]=" true"
  [showDismissBtn]="false"
  [disableConfirm]="((!imgChangeEvt || (imgChangeEvt && !imgChangeEvt['srcElement'].files.length)) && !removeAvatarButton) || showError"
  [title]="'INPUT.UPLOAD_AVATAR' | translate"
  [style]="{height: '95%', 'max-height': '500px', width: '95%', 'max-width': '600px'}"
  [maximizable]="false"
  [otherButtons]="removeAvatarButton ? [removeAvatarButton] : []"
  (confirm)="sendImage()"
  (cancel)="clearImg('cancel modal')"
>
    <div class="container text-center">
        <div class="row">
            <div class="col-md-9" style="text-align: right;">
                <label *ngIf="!imgChangeEvt || (imgChangeEvt && !imgChangeEvt['srcElement'].files.length)" style="display: inline-flex;padding: 0.714rem 1rem;">{{'INPUT.SELECT_IMG' | translate}}</label>
                <label *ngIf="imgChangeEvt && imgChangeEvt['srcElement'].files.length" style="display: inline-flex;padding: 0.714rem 1rem;">{{imgChangeEvt['srcElement'].files[0].name}}</label>
            </div>
            <div class="col-md-3" style="text-align: left;">
                <label class="p-button" style="margin-right: 5px;">
                    <input type="file" #inputFile [accept]="getFileTypes()" (change)="onFileChange($event)"/>
                    {{'INPUT.BROWSE' | translate}}
                </label>
                <!-- <p-button
                    icon="pi pi-trash"
                    [disabled]="!imgChangeEvt"
                    (onClick)="clearImg()">
                </p-button> -->
            </div>
        </div>
        <div class="row">
            <div class="col-md-6" style="padding: 15px;">
                <label>{{'INPUT.IMG' | translate}}</label>
                <div class="card-img">
                    <image-cropper
                        *ngIf="imgChangeEvt && imgChangeEvt['srcElement'].files.length || imageUrl"
                        [imageURL]="imageUrl"
                        [imageChangedEvent]="imgChangeEvt" 
                        [maintainAspectRatio]="true"
                        [resizeToHeight]="182"
                        [format]="isJpgAvatar ? 'jpeg' : 'png'"
                        (imageCropped)="cropImg($event)"
                        style="align-items: center;">
                    </image-cropper>
                    <label *ngIf="(!imgChangeEvt || (imgChangeEvt && !imgChangeEvt['srcElement'].files.length)) && !imageUrl" style="align-self: center;">{{'INPUT.NO_IMAGE' | translate}}</label>
                </div>
            </div>
            <div class="col-md-6" style="padding: 15px;">
                <label>{{'INPUT.PREVIEW_IMG' | translate}}</label>
                <div class="card-img">
                    <img *ngIf="cropImgPreview" [src]="cropImgPreview" style="margin: auto;" />
                    <label *ngIf="!cropImgPreview" style="align-self: center;">{{'INPUT.NO_IMAGE' | translate}}</label>
                </div>
            </div>
        </div>
    </div>
</custom-modal>

<custom-modal
  id="id_removeAvatarModal"
  #removeAvatarModal
  [showCancelBtn]=" true"
  [showDismissBtn]="false"
  [title]="'INPUT.REMOVE_AVATAR' | translate"
  [style]="{height: '95%', 'max-height': '200px', width: '95%', 'max-width': '600px'}"
  [maximizable]="false"
  (confirm)="removeImg()"
  (cancel)="removeAvatarModalCancel('cancel modal')"
>
    <div class="container text-center">
        <label>{{'INPUT.CONFIRM_REMOVE_AVATAR' | translate}}</label>
    </div>
</custom-modal>