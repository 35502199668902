<div class="fake-login">
    <custom-card [cssClass]="'card alignCenter'" class="login">
        <div class="logo">
          <img src="assets/logo2.png" alt="logo">
          <span>{{'APPLICATION.SUBTITLE' | translate}}</span>
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

            <custom-input-text
                formControlName="username"
                [label]="'FAKE_LOGIN.FORM.USERNAME' | translate"
                customFormatInputText
            ></custom-input-text>
            <custom-input-text
                formControlName="password"
                [input-type]="inputType.PASSWORD"
                [label]="'FAKE_LOGIN.FORM.PASSWORD' | translate"
                [error-labels]="errorLabels['maxLengthName']"
                customFormatInputText
            ></custom-input-text>

            <div>
              <p-button
                id="id_buttonConfirmFormModule"
                [label]="'COMMON.CONFERMA' | translate"
                [disabled]="!form.valid"
                type="submit">
              </p-button>
            </div>

          </form>
    </custom-card>
</div>