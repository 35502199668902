<div class="layout-wrapper" [ngClass]="containerClass" *ngIf="currentPage!=='/login'">
    <app-topbar></app-topbar>
    <div class="layout-sidebar" style="height: 126px;" *ngIf="user">
        <div style="margin: 10.5px 0;">
            <custom-user
                *ngIf="getUser()"
                [name]="
                user.given_name
                    ? user.given_name + ' ' + (user.family_name ? user.family_name : '')
                    : user.email
                    ? user.email
                    : ''
                "
                [additionalUserInfo]="userService.getOfficeName()"
                [avatar]="gestioneUserInfoService.getAvatarImage()"
                [useDefaultLogoutAction]="true"
                [getAvatar]="gestioneUserInfoService.getAvatar"
                [showDropdown]="false"
                [isVertical]="true"
            >
            </custom-user>
        </div>
    </div>
    <div class="layout-sidebar" [ngStyle]="!!user && {'top': 'calc(7rem + 135px)'}">
        <app-sidebar></app-sidebar>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <flowers-breadcrumb [cssClass]="'main-breadcrumb'"></flowers-breadcrumb>
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <!-- <app-config></app-config> -->
    <div class="layout-mask"></div>
</div>

<div class="layout-wrapper" [ngClass]="containerClass" *ngIf="currentPage=='/login'">
    <router-outlet></router-outlet>
</div>
