import { Injectable, Injector } from '@angular/core';
import { CustomBaseHttpLiteService, CustomSearchCriteria } from '@pandora/flowers';
import { Endpoints } from '../endpoints/endpoints';
import { ConfigurationEndpointsConstants } from '../constans/configuration-endpoints.constants';
import { EgpService } from '../models/egpService.model';

@Injectable({
  providedIn: 'root'
})
export class EgpServiceService extends CustomBaseHttpLiteService<EgpService> {

  constructor(injector: Injector) {
    super(injector);
  }

  override getHost(): string {
    return ConfigurationEndpointsConstants.API.url.workflow;
  }
  
  buildSearchParams(criteria: CustomSearchCriteria): any {
    const params = {};
    return params;
  }
  
  getBaseResourceEndpoint(): string {
    return Endpoints.EGP_SERVICES;
  }

  getServiceName(): string {
    return 'EgpServiceService';
  }
}
