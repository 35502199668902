import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import {ShowProfileComponent} from './core/components/show-profile/show-profile.component';
import { FakeLoginComponent } from './core/components/fake-login/fake-login.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'Home', breadcrumb: 'Home' },
  },
  {
    path: ':idp/home',
    component: HomeComponent,
    data: { title: 'Home', breadcrumb: 'Home' },
  },
  {
    path: 'login',
    component: FakeLoginComponent,
    data: { title: 'Login', breadcrumb: '' },
  },
  {
    path: 'profile',
    data: {
      title: 'PROFILE.PAGE_TITLE',
      breadcrumb: 'PROFILE.PAGE_TITLE',
    },
    children: [
      {
        path: '',
        component: ShowProfileComponent,
        data: {
          title: 'PROFILE.PAGE_TITLE',
          breadcrumb: 'PROFILE.PAGE_TITLE',
        },
      },
    ]
  },
  {
    path: 'workflow',
    loadChildren: () =>
      import('./modules/workflow/workflow.module').then(
        (m) => m.WorkflowModule
      ),
  },
  {
    path: 'reference',
    loadChildren: () =>
      import('./modules/reference/reference.module').then(
        (m) => m.ReferenceModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
