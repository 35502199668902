// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import keycloakConfig from '../keycloak.config';

export const environment = {
  production: false,
  keycloak: keycloakConfig,
  backendurl: { // 'https://egp-be.pandoradevelop.it/'
    dashboard: 'TODO',
    widget: 'TODO',
    user: 'https://authout-be.pandoradevelop.it/',
    workflow: 'https://egp.pandoradevelop.it/workflow-be/',
    geoserver: 'https://egp.pandoradevelop.it/geoserver/'
  },
  applicationName: 'EGP',
  defaultLang: 'it',
  demoMode: false,
  defaultEntryComponentPath: 'develop/home'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
