import { Component, Injector } from '@angular/core';
import { FlowersBasePageComponent } from '@pandora/flowers';
/* import { UserService } from './core/services/user.service';
import {GestioneUserInfoService} from './shared/services/gestione-user-info.service'; */

@Component({
  selector: 'flowers-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends FlowersBasePageComponent {
  
  constructor(
    injector: Injector,
    /* public userService: UserService,
    public gestioneUserInfoService: GestioneUserInfoService */
  ) {
    super(injector);
  }

  pageName(): string {
    return 'AppComponent';
  }

  ngOnInitForChildren(): void {
  }

  ngOnDestroyForChildren(): void {}
}
