import {Component, Injector, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {CustomSearchResultSettings, CustomTableColumnDef, FilterBase, FlowersSearchBasePageComponent, JsonPatchDto, JsonPatchOperations, SearchResult, ToastService} from '@pandora/flowers';
import { NavigateService } from '../../../core/services/navigate.service';
import { WorkflowService } from '../../services/workflow.service';
import { Observable, forkJoin, map, of } from 'rxjs';
import { Workflow } from '../../models/workflow.model';
import { JobService } from '../../services/job.service';
import { EgpServiceService } from '../../services/egpService.service';
import { CronParserPipe } from '../../pipes/cron-parser.pipe';
import { DaysOfWeekEnum } from '../../constans/daysOfWeek.enum';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'workflow-search',
  templateUrl: './workflow-search.component.html',
  styleUrls: ['./workflow-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WorkflowSearchComponent extends FlowersSearchBasePageComponent<Workflow> {
  cols: CustomTableColumnDef[]
  daysOfWeekArray = Object.values(DaysOfWeekEnum);

  startString: string;
  stopString: string;
  startedString: string;
  stoppedString: string;

  private interval;

  constructor(
    injector: Injector,
    public translateService: TranslateService,
    private readonly workflowService: WorkflowService,
    private readonly jobService: JobService,
    private readonly egpServiceService: EgpServiceService,
    private readonly navigateService: NavigateService,
    private confirmationService: ConfirmationService,
    private toastService: ToastService
  ) {
    super(injector);
    this.startSearchOnInit = true;
    this.defineColumns();

    this.startString = this.translateService.instant('WORKFLOW.START_AND_STOP.START');
    this.stopString = this.translateService.instant('WORKFLOW.START_AND_STOP.STOP');
    this.startedString = this.translateService.instant('WORKFLOW.START_AND_STOP.STARTED');
    this.stoppedString = this.translateService.instant('WORKFLOW.START_AND_STOP.STOPPED');
  }

  defineFiltersCriteria(filters: FilterBase[]): FilterBase[] {
    return [];
  }

  getDefaultSortField(): string {
    return '';
  }

  defineDataDisplaySettings(m: any): CustomSearchResultSettings {
    return { };
  }

  defineColumns(): void {
    this.cols = [
      { field: 'code', description: 'WORKFLOW.TABLE.CODE' },
      { field: 'description', description: 'WORKFLOW.TABLE.DESCRIPTION' },
      { field: 'serviceDescription', description: 'WORKFLOW.TABLE.SERVICE_DESCRIPTION' },
      { field: DaysOfWeekEnum.MONDAY, description: 'WORKFLOW.TABLE.MONDAY' },
      { field: DaysOfWeekEnum.TUESDAY, description: 'WORKFLOW.TABLE.TUESDAY' },
      { field: DaysOfWeekEnum.WEDNESDAY, description: 'WORKFLOW.TABLE.WEDNESDAY' },
      { field: DaysOfWeekEnum.THURSDAY, description: 'WORKFLOW.TABLE.THURSDAY' },
      { field: DaysOfWeekEnum.FRIDAY, description: 'WORKFLOW.TABLE.FRIDAY' },
      { field: DaysOfWeekEnum.SATURDAY, description: 'WORKFLOW.TABLE.SATURDAY' },
      { field: DaysOfWeekEnum.SUNDAY, description: 'WORKFLOW.TABLE.SUNDAY' },
      { field: 'hours', description: 'WORKFLOW.TABLE.HOURS' },
      { field: 'status', description: 'WORKFLOW.TABLE.LAST_JOB_STATUS' },
      { field: 'start', description: 'WORKFLOW.TABLE.START_AND_STOP' }
    ];
  }

  search(criteriaDto: any): Observable<SearchResult<Workflow>> {
    return this.workflowService.getAllSimple(criteriaDto);
  }

  override manageResults(searchResult: SearchResult<Workflow>): Observable<SearchResult<Workflow>> {
    if (searchResult.content.length === 0) {
      return of(searchResult);
    }

    const cronPipe = new CronParserPipe();

    const requests = searchResult.content.map(workflow => {
      const parsedCronTab = cronPipe.transform(workflow['data'].cronTab);
      if (parsedCronTab) {
        if (parsedCronTab.hours) {
          workflow['data']['hours'] = parsedCronTab.hours.toString() + ":" + parsedCronTab.minutes.toString().padStart(2, '0');
        }
        for (const day of parsedCronTab.days) {
          workflow['data'][day] = true;
        }
      }

      const jobsParams = {
        workflowId: workflow['data'].id,
        page: 0,
        size: 1,
        sort: 'startDate,desc'
      }

      return forkJoin({
        egpServices: this.egpServiceService.get(workflow['data'].serviceId),
        jobs: this.jobService.getAllSimple(jobsParams)
      }).pipe(
        map(res => {
          workflow['data'].serviceDescription = res.egpServices['description'];
          workflow['data'].status = res.jobs.content.length > 0 ? res.jobs.content[0]['data'].status : '_';
          workflow['data'].currentJob = res.jobs.content.length > 0 ? res.jobs.content[0]['data'] : null;

          return workflow;
        })
      );
    });

    return forkJoin(requests).pipe(
      map(workflows => {
        searchResult.content = workflows;
        return searchResult;
      })
    );
  }

  buildStaticFilters(criteriaDto: any): void {
    criteriaDto.enabled = true;
    criteriaDto.sort = ['serviceId', 'code'];
  }

  pageName(): string {
    return 'WorkflowSearch';
  }

  async ngOnInitForChildren(): Promise<void> {
    this.interval = setInterval(() => {
      this.resetSearch();
    }, 60000);
  }

  ngOnDestroyForChildren(): void {
    clearInterval(this.interval);
  }

  toggleStartAndStop = (event: Event, wf: Workflow) => {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      message: this.translateService.instant('WORKFLOW.START_AND_STOP.CONFIRM', { action: wf.status === 'RUNNING' ? this.stopString : this.startString }),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.confirmAction(wf);
      },
      reject: () => {
        this.toastService.showWarning(
          this.translateService.instant('WORKFLOW.START_AND_STOP.REJECTED_TITLE'),
          this.translateService.instant('WORKFLOW.START_AND_STOP.REJECTED_TEXT', {status: wf.status === 'RUNNING' ? this.stoppedString : this.startedString })
        );
      }
    });
  }

  confirmAction = (wf: Workflow) => {
    if (wf.status !== 'RUNNING') {
      this.jobService.save({ workflowId: wf.id as number }).subscribe(response => {
        wf.currentJob = response;
        this.confirmToast(true);
      });
      wf.status = 'RUNNING';
    } else if (wf.status === 'RUNNING') {
      const dto: JsonPatchDto[] = [
        {
          op: JsonPatchOperations.REPLACE,
          path: '/status',
          value: 'STOPPED',
        },
      ];
      this.jobService.jsonPatch(dto, wf.currentJob.id).subscribe(response => {
        wf.currentJob = response;
        this.confirmToast(false);
      });
      wf.status = 'STOPPED';
    }
  }

  confirmToast = (started: boolean) => {
    this.toastService.showSuccess(
      this.translateService.instant('WORKFLOW.START_AND_STOP.CONFIRMED_TITLE'),
      this.translateService.instant('WORKFLOW.START_AND_STOP.CONFIRMED_TEXT', {status: started ? this.startedString : this.stoppedString})
    );
  }
}
