import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector, inject } from '@angular/core';
import { AuthFacade, CustomHttpInterceptor, SpinnerService, UiConfigFacade, User } from '@pandora/flowers';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class EgpHttpInterceptor extends CustomHttpInterceptor {
  loader: SpinnerService;
  uiConfig: UiConfigFacade;
  isSpinnerEnabled: boolean;
  userService: UserService;

  constructor(injector: Injector) {
    super(injector);
    this.loader = inject(SpinnerService);
    this.uiConfig = inject(UiConfigFacade);
    this.userService = inject(UserService);

    this.uiConfig.getIsSpinnerEnabled().subscribe((isSpinnerEnabled: boolean) => this.isSpinnerEnabled = isSpinnerEnabled);
  }

  override intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.isSpinnerEnabled) {
      this.loader.enable();
    }

    let authReq = request;

    if (this.userService.user && this.userService.user.profileSelected) {
      authReq = request.clone({ headers: request.headers.set('Summer-profile-uuid', this.userService.user.profileSelected.uuid) });
    }

    return next.handle(authReq)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.userService.logout();
        }
        if (this.isSpinnerEnabled) {
          this.loader.disable();
        }
        throw this.manageError(err);
      })
    )
    .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
      if (evt instanceof HttpResponse && this.isSpinnerEnabled) {
        this.loader.disable();
      }
      return evt;
    }));
  }
}
