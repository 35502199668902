import { Injectable, Injector, inject } from '@angular/core';
import {
  CustomBaseHttpLiteService,
  CustomSearchCriteria,
} from '@pandora/flowers';
import { ConfigurationEndpointsConstants } from '../constans/configuration-endpoints.constants';
import { Endpoints } from '../endpoints/endpoints';
import { UserInfo } from '../models/userinfo.model';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { WidgetResponse } from '../models/widget-response.model';

@Injectable({
  providedIn: 'root',
})
export class WidgetWorkflowService extends CustomBaseHttpLiteService<WidgetResponse> {

  translateService: TranslateService;

  constructor(injector: Injector,) {
    super(injector);
    this.translateService = inject(TranslateService);
  }

  override getHost(): string {
    return ConfigurationEndpointsConstants.API.url.workflow; // TODO change to .widget
  }

  buildSearchParams(criteria: CustomSearchCriteria): any {
    const params = {};
    return params;
  }

  getBaseResourceEndpoint(): string {
    return Endpoints.WIDGET;
  }

  getServiceName(): string {
    return 'WidgetWorkflowService';
  }

  getPsGroupVelocity() {
    const url = `${this.buildUrl()}${'getPsGroupVelocity'}`;
    return this.httpClient.get(url);
  }

  getSnapshotAOIWaterQuality() {
    const url = `${this.buildUrl()}${'images/wq'}`;
    return this.httpClient.get(url);
  }

  getSnapshotAOIChangeDetection() {
    const url = `${this.buildUrl()}${'images/cd'}`;
    return this.httpClient.get(url);
  }

}
