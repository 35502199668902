import {
  TranslateLoader,
  TranslateModuleConfig,
  TranslateService,
} from '@ngx-translate/core';
import {
  HttpBackend,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, filter, map } from 'rxjs';
import { APP_INITIALIZER, Injector, LOCALE_ID } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';
import { it, en } from '@pandora/flowers';
import { environment } from './environments/environment';

export class TranslateHttpLoader implements TranslateLoader {
  constructor(
      private httpHandler: HttpBackend,
      public prefix: string,
      public suffix: string,
  ) { }

  getTranslation(lang: string): Observable<unknown> {
      const httpRequest = new HttpRequest('GET', `${this.prefix}${lang}${this.suffix}`);

      return this.httpHandler.handle(httpRequest)
          .pipe(
              filter((httpEvent) => httpEvent instanceof HttpResponse),
              map((httpEvent) => {
                if (httpEvent instanceof HttpResponse) {
                  return httpEvent.body;
                }
              }),
          );
  }
}

export function translateLoaderFactory(httpHandler: HttpBackend): TranslateLoader {
  return new TranslateHttpLoader(httpHandler, './assets/i18n/', '.json');
}

export function translateResolver(
  translateService: TranslateService,
  injector: Injector
) {
  return () =>
    new Promise<any>((resolve: any) => {
      const locationInitialized = injector.get(
        LOCATION_INITIALIZED,
        Promise.resolve(null)
      );
      locationInitialized.then(() => {

        const translations = {
          en: en,
          it: it
        }

        translateService.addLangs(Object.keys(translations));

        const browserLanguage = translateService.getBrowserLang();
        const defaultLanguage = environment.defaultLang;
        translateService.setDefaultLang(defaultLanguage);
        const langToUse = translateService.getLangs().includes(browserLanguage) ? browserLanguage : defaultLanguage;

        translateService.use(langToUse).subscribe((json) => {
            // merge labels from libraries with those of the application
            translateService.setTranslation(langToUse, translations[langToUse], true);
            resolve(true);
          });
      });
    });
}

export const TRANSLATE_LOADER: TranslateModuleConfig = {
  loader: {
      provide: TranslateLoader,
      useFactory: translateLoaderFactory,
      deps: [
          HttpBackend,
      ],
  },
};

export const TRANSLATE_PROVIDER = {
  provide: APP_INITIALIZER,
  useFactory: translateResolver,
  deps: [TranslateService, Injector],
  multi: true,
};

export const LOCALE_PROVIDER = {
  provide: LOCALE_ID,
  useFactory: (translateService) => translateService.currentLang,
  deps: [TranslateService, Injector],
};
