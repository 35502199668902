import { Pipe, PipeTransform } from '@angular/core';
import { DaysOfWeekEnum } from '../constans/daysOfWeek.enum';

@Pipe({
  name: 'cronParser'
})
export class CronParserPipe implements PipeTransform {

  transform(value: string): any {
    if (!value) {
      return null;
    }

    const parts = value.split(' ');
    if (parts.length !== 5) {
      return { error: 'Formato cronTab non valido' };
    }

    const week = {
      0: DaysOfWeekEnum.SUNDAY,
      1: DaysOfWeekEnum.MONDAY,
      2: DaysOfWeekEnum.TUESDAY,
      3: DaysOfWeekEnum.WEDNESDAY,
      4: DaysOfWeekEnum.THURSDAY,
      5: DaysOfWeekEnum.FRIDAY,
      6: DaysOfWeekEnum.SATURDAY,
    };

    const minutes = parts[0];
    const hours = parts[1];

    const days = parts[4].split(',').map(dayIndex => {
      const index = parseInt(dayIndex, 10);
      return week[index];
    });

    return {
      minutes: minutes,
      hours: hours,
      days: days
    };
  }
}
