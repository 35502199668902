import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LOCALE_PROVIDER, TRANSLATE_LOADER, TRANSLATE_PROVIDER } from '../translate-initializer';
import { HomeComponent } from './home/home.component';
import { ToastrModule } from 'ngx-toastr';
import { StoreModule } from '@ngrx/store';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { Utils } from './shared/utils/utils';
import { CoreModule } from './core/core.module';
import { AppLayoutModule } from './layout/app.layout.module';
import {BrowserModule} from '@angular/platform-browser';
import {SharedModule} from './shared/egp-shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ImageModule } from 'primeng/image';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,

    ToastrModule.forRoot({
      newestOnTop: false,
      maxOpened: 4,
      autoDismiss: true,
    }),
    StoreModule.forRoot({}),
    TranslateModule.forRoot(TRANSLATE_LOADER),
    NgxSpinnerModule.forRoot({ type: 'ball-atom' }),

    CoreModule,
    SharedModule,
    AppRoutingModule,
    AppLayoutModule,
    ImageModule
  ],
  providers: [
    TRANSLATE_PROVIDER,
    Utils,
    LOCALE_PROVIDER
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
