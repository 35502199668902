<h1>
  {{pageTitle | translate}}
</h1>
<!-- <flowers-loading-spinner [loading]="!showResult" [inline]="true"></flowers-loading-spinner> Già presente ngx-spinner a livello di app.component.html -->
<p-table *ngIf="showResult && showResult.content" [value]="showResult.content">
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of cols">
                {{col.description | translate}}
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-workflow>
        <tr>
            <td *ngFor="let col of cols">
                <div [ngSwitch]="col.field">
                    <!-- Caso per 'status' -->
                    <div *ngSwitchCase="'status'">
                        {{ ('WORKFLOW.JOB_STATUS.' + workflow['data'][col.field]) | translate }}
                    </div>

                    <!-- Caso per i giorni della settimana -->
                    <div *ngFor="let day of daysOfWeekArray">
                        <ng-template [ngSwitchCase]="day">
                            <i class="pi pi-check" *ngIf="workflow['data'][col.field]"></i>
                            <span *ngIf="!workflow['data'][col.field]"> - </span>
                        </ng-template>
                    </div>

                    <div *ngSwitchCase="'start'">
                      <p-confirmPopup></p-confirmPopup>
                      <p-button *ngIf="workflow['data']['lastJobStatus'] !== 'RUNNING' && workflow['data']['status'] !== 'RUNNING'"
                                icon="pi pi-play" (click)="toggleStartAndStop($event, workflow['data'])"
                                [title]="translateService.instant('WORKFLOW.START_AND_STOP.TOOLTIP_START')">
                      </p-button>
                      <p-button *ngIf="workflow['data']['lastJobStatus'] === 'RUNNING' || workflow['data']['status'] === 'RUNNING'"
                                icon="pi pi-times" (click)="toggleStartAndStop($event, workflow['data'])"
                                [title]="translateService.instant('WORKFLOW.START_AND_STOP.TOOLTIP_STOP')">
                      </p-button>
                    </div>

                    <!-- Caso default -->
                    <div *ngSwitchDefault>
                        {{workflow['data'][col.field]}}
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
