<div *ngIf="userService.user">
    <h1>
        {{'HOME.PAGE_TITLE' | translate}}
    </h1>

    <div class="row" style="padding-bottom: 15px;">
      <p-card class="col-md-3" *ngFor="let result of headWidgetList">
        <div class="flex justify-content-between mb-3">
            <div>
                <span class="block text-500 font-medium mb-3">{{result.title}}</span>
                <div class="text-900 font-medium text-xl">{{result.value}}</div>
            </div>
            <div [class]="'flex align-items-center justify-content-center border-round ' + result.iconContainer" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                <i [class]="'text-xl ' + result.icon"></i>
            </div>
        </div>
        <span class="text-500" style="text-align: left;">{{result.subtitle}}</span>
      </p-card>
    </div>

    <div class="row">
      <p-card class="col-12">
        <h5 style="text-align: left;">Workflow - Storico esecuzioni</h5>

        <p-table [value]="workflowPastExecutions" scrollDirection="both" [scrollable]="true" scrollHeight="400px" responsiveLayout="scroll">
          <ng-template pTemplate="header">
            <tr>
              <th style="width:200px" pFrozenColumn>Workflow</th>
              <!--<th style="width:200px" alignFrozen="left" pFrozenColumn [frozen]="false">Id</th>-->
              <th style="width:200px">Data - Ora inizio</th>
              <th style="width:200px">Data - Ora fine</th>
              <th style="width:200px">Stato</th>
              <th style="width:200px">Utente</th>
              <th style="width:200px">Esecuzione manuale</th>
              <th style="width:200px">Prossima esecuzione</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-workflow>
            <tr>
              <td style="width:200px" pFrozenColumn class="font-bold">{{workflow.name}}</td>
              <td style="width:200px" alignFrozen="left" pFrozenColumn [frozen]="false" [ngClass]="{'font-bold': false}">{{workflow.startDate}}</td>
              <td style="width:200px">{{workflow.endDate}}</td>
              <td style="width:200px">
                <span [class]="'workflow-badge status-' + workflow.status">{{workflow.status}}</span>
              </td>
              <td style="width:200px">
                <img [alt]="workflow.user.name" src="assets/demo/images/avatar/{{workflow.user.image}}" width="32" style="vertical-align: middle"/>
                <span class="image-text ml-2">{{workflow.user.name}}</span>
              </td>
              <td class="text-center">
                <i class="pi" [ngClass]="{'true-icon pi-check-circle text-green-500': workflow.manualStart}"></i>
              </td>
              <td style="width:200px">{{workflow.nextExecution}}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>

    <dragglebles [templates]="widgets" [disabled]="true" class="row dragglebles"></dragglebles>
    <ng-template #widgetsRef style="display: none;" *ngFor="let result of widgetList">
        <custom-card [cssClass]="'card alignCenter'" [card-title]="result.description">
            <chart *ngIf="result.type===widgetType.CHART" [widget]="result" [showLegend]="true" [legendTitle]="undefined" [view]=[getWidth(2),undefined]></chart>
            <p-image *ngIf="result.type===widgetType.IMAGE" [src]="result.items"></p-image>
        </custom-card>
    </ng-template>

</div>
