import {Component, Injector, ViewEncapsulation, ViewChild, ElementRef, SecurityContext} from '@angular/core';
import {CustomModalComponent, FlowersBasePageComponent, OtherButton, ToastService, User} from '@pandora/flowers';
import {Observable, ReplaySubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { GestioneUserInfoService } from '../../../shared/services/gestione-user-info.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from '../../../shared/utils/utils';

@Component({
  selector: 'show-profile',
  templateUrl: './show-profile.component.html',
  styleUrls: ['./show-profile.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShowProfileComponent extends FlowersBasePageComponent {
  user: User;
  userLabel: string;
  anagraficaInfo: any;

  public userService: UserService;
  private translateService: TranslateService;
  public gestioneUserInfoService: GestioneUserInfoService;

  toastService: ToastService;
  showError = false;
  @ViewChild('changeModal') changeModal: CustomModalComponent;
  @ViewChild('removeAvatarModal') removeAvatarModal: CustomModalComponent;
  @ViewChild('inputFile') inputFile: ElementRef;
  imgChangeEvt: any;
  private _fileBKP: File
  cropImgPreview: any;
  private _croppedImage: File;
  imageUrl: string;
  removeAvatarButton: OtherButton;
  isJpgAvatar = false;
  types = ['png', 'gif', 'jpeg', 'jpg'];
  maxFileSize = 1000000;
  private _avatar: string;
  private interval;
  private utils: Utils;
  private sanitizer: DomSanitizer;

  constructor(injector: Injector) {
    super(injector);

    this.userService = injector.get(UserService);
    this.translateService = injector.get(TranslateService);
    this.gestioneUserInfoService = injector.get(GestioneUserInfoService);
    this.utils = injector.get(Utils);
    this.sanitizer = injector.get(DomSanitizer);

    this.user = this.userService.getUser();

    if (this.gestioneUserInfoService && this.gestioneUserInfoService.userInfo && this.userService && this.userService.userInfoProfile) {
      const gruppoFields = {
        name: this.gestioneUserInfoService.userInfo.name,
        surname: this.gestioneUserInfoService.userInfo.surname,
        codiceFiscale: this.gestioneUserInfoService.userInfo.externalIdentifier,
        email: this.gestioneUserInfoService.userInfo.email,
        phone: this.gestioneUserInfoService.userInfo.phone,
        creationDate: this.userService.userInfoProfile.creationDate,
        exipirationDays: this.gestioneUserInfoService.userInfo.daysUntilPasswordExpiration
      };

      this.anagraficaInfo = gruppoFields;
    }

  }

  ngOnDestroyForChildren(): void {
    clearInterval(this.interval);
  }

  ngOnInitForChildren(): void {
    this.userLabel = `${this.user.given_name} ${this.user.family_name}`;

    this._avatar = this.gestioneUserInfoService.getAvatarImage();
    if (this._avatar) {
      this.imageUrl = this.utils.getImage(this._avatar);
      this.setRemoveAvatarButton();
    } else {
      this.interval = setInterval(async () => {
        this._avatar = this.gestioneUserInfoService.getAvatarImage();
        if (this._avatar) {
          this.imageUrl = this.utils.getImage(this._avatar);

          this.setRemoveAvatarButton();

          clearInterval(this.interval);
        }
      }, 1000);
    }
  }

  pageName(): string {
    return '';
  }

  /** AVATAR */

  setRemoveAvatarButton() {
    this.removeAvatarButton = {
      label: 'INPUT.REMOVE_AVATAR',
      event: () => {
        this.changeModal.close();
        this.removeAvatarModal.open();
      },
      cssClass: 'bg-danger'
    }
  }

  openChangeModal = async () => {
    if (this.removeAvatarModal.visible) {
      this.removeAvatarModal.close();
    }
    if (this._avatar) {
      this._avatar = await this.gestioneUserInfoService.getAvatar();
      this.imageUrl = this.utils.getImage(this._avatar);
      this.setRemoveAvatarButton();
    }
    this.changeModal.open();
  }

  async onFileChange(event: any): Promise<void> {
    if (event.target.files.length) {
      const isPNG = this._check([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]);
      const isJPEG = this._check([0xff, 0xd8, 0xff]);
      const isGIF = this._check([0x47, 0x49, 0x46, 0x38]);

      const file = event.target.files[0];
      const buffers = await this._readBuffer(file, 0, 8);
      const uint8Array = new Uint8Array(buffers);

      this.isJpgAvatar = isJPEG(uint8Array);

      if (!isPNG(uint8Array) && !this.isJpgAvatar && !isGIF(uint8Array)) {
        this.toastService.showError(
          this.translateService.instant('INPUT.IMG_TYPE_ERROR', { type: this.getFileTypes() }),
          ``
        );

        this.clearImg();
      } else {
        this.imgChangeEvt = event;
        this._fileBKP = [...event['srcElement'].files][0];
      }
    } else if (this._fileBKP) {
      const list = new DataTransfer();
      list.items.add(this._fileBKP);

      (this.imgChangeEvt['srcElement'].files as FileList) = list.files;
    }

    /* const type: string = (event.target.files[0].type as string).split('/')[1];

    if (!this.types.includes(type)) {
      this.toastService.showError(
        this.translateService.instant('INPUT.IMG_TYPE_ERROR', { type: this.getFileTypes() }),
        ``
      );

      this.clearImg();
    } else {
      this.imgChangeEvt = event;
    } */
  }

  removeAvatarModalCancel(event = '') {
    if (event === '' || (event === 'cancel modal' && !this.changeModal.visible)) {
      this.changeModal.open();
    }
  }

  getFileTypes() {
    let type: string;
    this.types.forEach(t => {
      if (!type) {
        type = '.'.concat(t);
      } else {
        type = type.concat(', .').concat(t);
      }
    });
    return type;
  }

  cropImg(event) {
    this.cropImgPreview = this.sanitizer.bypassSecurityTrustResourceUrl(event.objectUrl);

    const type = (event.blob.type as string).split('/')[1];
    this._croppedImage = new File([event.blob], 'avatar.'+type, {type: event.blob.type});

    this.showError = this._croppedImage.size > this.maxFileSize;
    if (this.showError) {
      this.toastService.showError(
        this.translateService.instant('INPUT.IMG_TOO_SIZE', { size: this.getSizeInfo() }),
        ``
      );
    }
  }

  clearImg(event = '') {
    if (event === '' || (event === 'cancel modal' && !this.changeModal.visible)) {
      this.inputFile.nativeElement.value = "";
      this.imgChangeEvt = undefined;
      this.cropImgPreview = undefined;
      this._croppedImage = undefined;
    }
  }

  async sendImage() {
    await this.gestioneUserInfoService.setAvatar(this._croppedImage);
    this._fileTob64(this._croppedImage).subscribe(base64 => {
      this._avatar = base64;
      this.imageUrl = this.utils.getImage(this._avatar);

      this.setRemoveAvatarButton();
    });
  }

  async removeImg() {
    await this.gestioneUserInfoService.deleteAvatar();

    this.imageUrl = undefined;
    this._avatar = undefined;
    this.removeAvatarButton = undefined;

    this.clearImg();
  }

  getSizeInfo() {
    return (Number((this.maxFileSize / 1000000).toFixed(2)) % 1) === 0 ? (this.maxFileSize / 1000000) : (this.maxFileSize / 1000000).toFixed(2)
  }

  private _fileTob64(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  private _readBuffer(file, start = 0, end = 2) {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file.slice(start, end));
    });
  }

  private _check(headers) {
    return (buffers, options = { offset: 0 }) =>
      headers.every(
        (header, index) => header === buffers[options.offset + index]
      );
  }
}
