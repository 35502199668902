import { Injectable, Injector } from '@angular/core';
import { CustomBaseHttpLiteService, CustomSearchCriteria } from '@pandora/flowers';
import { Endpoints } from '../endpoints/endpoints';
import { ConfigurationEndpointsConstants } from '../constans/configuration-endpoints.constants';
import { Workflow } from '../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService extends CustomBaseHttpLiteService<Workflow> {

  constructor(injector: Injector,) {
    super(injector);
  }

  override getHost(): string {
    return ConfigurationEndpointsConstants.API.url.workflow;
  }
  
  buildSearchParams(criteria: CustomSearchCriteria): any {
    const params = {};
    return params;
  }
  
  getBaseResourceEndpoint(): string {
    return Endpoints.WORKFLOW;
  }

  getServiceName(): string {
    return 'WorkflowService';
  }
}
