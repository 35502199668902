<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="" [style.pointer-events]="!!user ? 'auto' : 'none'" >
        <img src="assets/logo2.png" alt="logo">
        <span>{{'APPLICATION.SUBTITLE' | translate}}</span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <custom-user
            *ngIf="userService.getUser()"
            [userActions]="getUserActions()"
            [useDefaultLogoutAction]="true"
            [showDropdown]="false"
            [customLogout]="customLogout"
        >
        </custom-user>

        <p-button *ngIf="!userService.getUser()"
            [label]="'APPLICATION.LOGIN' | translate"
            (click)="login()" styleClass="p-button-link">
        </p-button>

        <div class="action">
            <span
              *ngFor="let action of userService.subheaderActions"
              [pTooltip]="action.tooltip | translate"
              [tooltipPosition]="action.tooltipPosition"
            >

              <div class="flex justify-content-center" *ngIf="action.items && action.items.length;else other_content">
                <p-toast></p-toast>
                <p-menu #menu [model]="action.items" [popup]="true" appendTo="body"></p-menu>
                <p-button (click)="menu.toggle($event)" [icon]="action.icon" [disabled]="action.disabled"></p-button>
              </div>

              <ng-template #other_content>
                <div class="flex justify-content-center">
                  <p-button (click)="action.command()" [icon]="action.icon" [disabled]="action.disabled"></p-button>
                </div>
              </ng-template>
            </span>
        </div>

        <!-- <button class="p-link layout-topbar-button">
            <i class="pi pi-calendar"></i>
            <span>Calendar</span>
        </button>
        <button class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
        <button class="p-link layout-topbar-button" [routerLink]="'/documentation'">
            <i class="pi pi-cog"></i>
            <span>Settings</span>
        </button> -->
    </div>
</div>
