import { Injectable, Injector, inject } from '@angular/core';
import {
  AuthService,
  CustomBaseHttpLiteService,
  CustomSearchCriteria,
  ToastService,
} from '@pandora/flowers';
import { ConfigurationEndpointsConstants } from '../constans/configuration-endpoints.constants';
import { Endpoints } from '../endpoints/endpoints';
import {AvatarModel, UserInfo} from '../models/userinfo.model';
import {lastValueFrom, Observable, of, ReplaySubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { TenantInfoModel } from '../models/tenant-info.model';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { avatar, isMaster, tenantInfo, userInfo } from '../../fake-user.constant';

@Injectable({
  providedIn: 'root',
})
export class GestioneUserInfoService extends CustomBaseHttpLiteService<UserInfo> {
  avatar: AvatarModel;
  isMaster: boolean;
  userInfo: UserInfo;
  tenantInfo: TenantInfoModel;
  toastService: ToastService;
  translateService: TranslateService;
  authService: AuthService;

  constructor(injector: Injector,) {
    super(injector);
    this.toastService = inject(ToastService);
    this.translateService = inject(TranslateService);
    this.authService = inject(AuthService);
  }

  override getHost(): string {
    return ConfigurationEndpointsConstants.API.url.user;
  }

  buildSearchParams(criteria: CustomSearchCriteria): any {
    const params = {};
    return params;
  }

  clear() {
    this.isMaster = undefined;
    this.userInfo = undefined;
    this.tenantInfo = undefined;
  }

  getBaseResourceEndpoint(): string {
    return Endpoints.GESTIONE_USER_INFO;
  }

  getServiceName(): string {
    return 'GestioneUserInfoService';
  }

  public getWithWrapper(): Observable<UserInfo> {
    if (environment.demoMode) {
      this.userInfo = userInfo;
    } else {
      const url = `${this.buildUrl()}${'/authoutwrapper'}`;
      return this.httpClient.get(url).pipe(map((response) => this.userInfo = response['content'] as UserInfo));
    }
    return of(this.userInfo);
  }

  public getIsMaster(): Observable<boolean> {
    if (this.isMaster === undefined) {
      if (environment.demoMode) {
        this.isMaster = isMaster;
      } else {
        const url = `${this.buildUrl()}${'/tenant/isMaster'}`;
        return this.httpClient.get(url).pipe(map((response) => this.isMaster = response as boolean));
      }
    }
    return of(this.isMaster);
  }

  public getTenantInfo(urlParam): Observable<TenantInfoModel> {
    if (!this.tenantInfo) {
      if (environment.demoMode) {
        this.tenantInfo = tenantInfo;
      } else {
        const url = `${this.buildUrl(null, false)}${'tenants/'+ urlParam}`; // auth-out
      return this.httpClient.get(url).pipe(map((response) => this.tenantInfo = response['content'] as TenantInfoModel));
      }
    }
    return of(this.tenantInfo);
  }

  public getAvatarBE(): Observable<AvatarModel> {
    if (!this.avatar) {
      if (environment.demoMode) {
        this.avatar = avatar;
      } else {
        const url = `${this.buildUrl(null)}/avatar`;
        return this.httpClient.get(url).pipe(map((response) => this.avatar = response['content']));
      }
    }
    return of(this.avatar);
  }

  public getAvatarImage(): string {
    if (environment.demoMode) {
      return avatar.image;
    }
    return this.avatar?.image;
  }

  public setAvatar = async (file: File): Promise<void> => {
    if (!environment.demoMode) {
      const url = `${this.buildUrl(null)}/avatar`;
      const formData: FormData = new FormData();
      formData.append('file', file);
      const c = this.saveFile(formData, url).pipe(map((_) => {
        this.toastService.showSuccess(
          this.translateService.instant('GESTIONE_UTENTE.AVATAR_ADDED'),
          ``
        );
      }));
      await lastValueFrom(c);
      this.avatar = undefined;
    } else {
      this._fileTob64(file).subscribe(base64 => {
        this.avatar.image = base64;
      });

      this.toastService.showSuccess(
        this.translateService.instant('GESTIONE_UTENTE.AVATAR_ADDED'),
        ``
      );
    }

    const cc = this.getAvatarBE();
    await lastValueFrom(cc);
  }

  private _fileTob64(file : File) : Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(event.target.result.toString()));
    return result;
  }

  public deleteAvatar = async (): Promise<void> => {
    if (environment.demoMode) {
      this.avatar = undefined;
      this.toastService.showSuccess(
        this.translateService.instant('GESTIONE_UTENTE.AVATAR_DELETED'),
        ``
      );
    } else {
      const url = `${this.buildUrl(null)}/avatar`;
      const c = this.httpClient.delete(url).pipe(map((_) => {
        this.avatar = undefined;

        this.toastService.showSuccess(
          this.translateService.instant('GESTIONE_UTENTE.AVATAR_DELETED'),
          ``
        );
      }));

      await lastValueFrom(c);
    }
  }

  public getAvatar = async (): Promise<string> => {
    if (environment.demoMode) {
      return Promise.resolve(avatar.image);
    }

    const url = `${this.buildUrl(null)}/avatar`;
    const c = this.httpClient.get(url).pipe(map((response) => (response['content'] as AvatarModel).image));

    return await lastValueFrom(c);

  }

}
