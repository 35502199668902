/* eslint-disable @typescript-eslint/no-empty-function */
import { Component, ElementRef, Injector, QueryList, SecurityContext, TemplateRef, ViewChild, ViewChildren, inject } from '@angular/core';
import { AuthService, CustomCardComponent, FlowersBasePageComponent, PermissionService, User, WidgetChartModel, WidgetType } from '@pandora/flowers';
import { UserService } from '../core/services/user.service';
import { UiElement } from '../core/services/ui-element.service';
import { NavigateService } from '../core/services/navigate.service';
import { WidgetWorkflowService } from '../shared/services/widget-workflow.service';
import { lastValueFrom, map, takeUntil } from 'rxjs';
import { WidgetModel } from '@pandora/flowers';
import { PermissionEnum } from '../shared/constans/permissions.enum';
// import { WidgetResponse } from '../shared/models/widget-response.model';
import { DragulaService } from 'ng2-dragula';
import { Utils } from '../shared/utils/utils';
// import { WidgetGeoserverService } from '../shared/services/widget-geoserver.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends FlowersBasePageComponent {

  @ViewChildren("widgetsRef") widgets: QueryList<TemplateRef<any>>;

  userService: UserService;
  authService: AuthService;
  navigateService: NavigateService;
  uiElement: UiElement;
  widgetWorkflowService: WidgetWorkflowService;
  // widgetGeoserverService: WidgetGeoserverService;
  widgetList: WidgetModel[] = [];
  headWidgetList: Array<{title: string; subtitle: string; value: string; icon: string; iconContainer: string }> = [];
  permissionService: PermissionService;
  dragulaService: DragulaService;
  widgetType = WidgetType;
  interval;
  apiCalled = false;
  private utils: Utils;

  workflowPastExecutions: WorkflowPastExecutions[] = [];

  constructor(injector: Injector) {
    super(injector);

    this.userService = inject(UserService);
    this.authService = inject(AuthService);
    this.navigateService = inject(NavigateService);
    this.uiElement = inject(UiElement);
    this.widgetWorkflowService = inject(WidgetWorkflowService);
    // this.widgetGeoserverService = inject(WidgetGeoserverService);
    this.permissionService = inject(PermissionService);
    this.dragulaService = inject(DragulaService);
    this.utils = injector.get(Utils);
  }

  init() {
    this.interval = setInterval(() => {
      if (this.userService.user) {
        if (this.userService.user['authorization-details'].authorization.length && (!('profileSelected' in this.userService.user) || !this.userService.user.profileSelected) && this.userService.applicationNameModel()) {
          this.navigateService.goTo('profile');
        } else if( this.permissionService.checkElementPermissions([PermissionEnum.DASHBOARD], this.userService.user)){
          this.getWidgets();
        }
        clearInterval(this.interval);
      }

    }, 1000);

    this.headWidgetList = [
      {title: 'Servizi attivi', value: '3', subtitle: '1 attivato negli ultimi 30 giorni', icon: 'pi pi-sitemap text-blue-500', iconContainer: 'bg-blue-100'},
      {title: 'Running totali', value: '358', subtitle: '52 negli ultimi 30 giorni', icon: 'pi pi-caret-right text-cyan-500', iconContainer: 'bg-cyan-100'},
      {title: 'Running fallite', value: '1', subtitle: '1 negli ultimi 30 giorni', icon: 'pi pi-times-circle text-red-500', iconContainer: 'bg-red-100'},
      {title: 'Warning sollevati', value: '32', subtitle: '8 negli ultimi 30 giorni', icon: 'pi pi-bell text-purple-500', iconContainer: 'bg-purple-100'},
    ];

    this.workflowPastExecutions = [
      {
        name: "Ground Motion",
        startDate: "12/12/2023 - 22:50",
        endDate: "13/12/2023 - 04:43",
        nextExecution: "13/12/2023 - 22:50",
        status: "completed",
        manualStart: false,
        user: {
          name: "SYSTEM",
          image: "robot_avatar.jpg"
        },
      },
      {
        name: "Water Quality",
        startDate: "03/12/2023 - 21:00",
        endDate: "03/12/2023 - 21:15",
        nextExecution: "04/12/2023 - 21:00",
        status: "completed",
        manualStart: false,
        user: {
            name: "SYSTEM",
            image: "robot_avatar.jpg"
        },
      },
      {
        name: "Water Quality",
        startDate: "02/12/2023 - 21:00",
        endDate: "02/12/2023 - 21:15",
        nextExecution: "03/12/2023 - 21:00",
        status: "stopped",
        manualStart: false,
        user: {
          name: "SYSTEM",
          image: "robot_avatar.jpg"
        },
      },
      {
        name: "Change Detection",
        startDate: "29/11/2023 - 10:32",
        endDate: "29/11/2023 - 10:40",
        nextExecution: "",
        status: "completed",
        manualStart: true,
        user: {
          name: "Micaela Esposito",
          image: "amyelsner.png"
        },
      },
      {
        name: "Water Quality",
        startDate: "28/11/2023 - 21:00",
        endDate: "28/11/2023 - 21:15",
        nextExecution: "03/12/2023 - 21:00",
        status: "completed",
        manualStart: false,
        user: {
          name: "SYSTEM",
          image: "robot_avatar.jpg"
        },
      },
      {
        name: "Change Detection",
        startDate: "25/11/2023 - 11:45",
        endDate: "25/11/2023 - 11:52",
        nextExecution: "",
        status: "completed",
        manualStart: true,
        user: {
          name: "Cosimo Salierno",
          image: "xuxuefeng.png"
        },
      },
      {
        name: "Change Detection",
        startDate: "22/11/2023 - 09:38",
        endDate: "22/11/2023 - 09:39",
        nextExecution: "",
        status: "failed",
        manualStart: true,
        user: {
          name: "Cosimo Salierno",
          image: "xuxuefeng.png"
        },
      },
    ]

  }

  formatCurrency(value: number) {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
}

  getWidth(col: number) {
    const box: HTMLElement = document.querySelector('.dragglebles');
    const width = box.offsetWidth;

    return (width / col) - (30 * col);
  }

  getWidgets(): void {
    if (!this.apiCalled) {
      this.apiCalled = true;

      this.widgetWorkflowService.getPsGroupVelocity()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (response) => {
            this.widgetList.push(
              {
                description: 'HOME.GROUND_MOTION',
                items: response['content'],
                type: WidgetType.CHART
              }
            )
          }
      );

      this.widgetWorkflowService.getSnapshotAOIWaterQuality()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (response) => {
            this.widgetList.push(
              {
                description: 'HOME.WATER_QUALITY',
                items: this.utils.getImage(response['content']['image']),
                type: WidgetType.IMAGE
              }
            )
          }
      );

      this.widgetWorkflowService.getSnapshotAOIChangeDetection()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          (response) => {
            this.widgetList.push(
              {
                description: 'HOME.CHANGE_DETECTION',
                items: this.utils.getImage(response['content']['image']),
                type: WidgetType.IMAGE
              }
            )
          }
      );

    }
  }

  ngOnDestroyForChildren(): void {
    this.dragulaService.destroy('COLUMNS');
    clearInterval(this.interval);
  }

  ngOnInitForChildren(): void {
    this.init();
  }

  pageName(): string {
    return 'HomeComponent';
  }
}

class WorkflowPastExecutions {
  nextExecution: string;
  startDate: string;
  endDate: string;
  name: string;
  user: {name: string, image: string};
  status: string;
  manualStart: boolean
}
